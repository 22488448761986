var mainSlider = {

  swiper: null,
  $slider: null,
  $pagination: null,

  init: function() {
      this.$slider = $('.banner--slider .swiper-container');

      this.swiper = new Swiper(this.$slider[0], {
          spaceBetween: 0,
          effect: 'fade',
          loop: true,
          navigation: {
              nextEl: '.banner__arrow--right',
              prevEl: '.banner__arrow--left'
          },
          autoplay: {
              delay: 5000
          },
          pagination: {
              el: '.swiper-pagination',
              clickable: true
          },
          fadeEffect: {
              crossFade: true
          },
          on: {
              init: function(){
                  swiperAnimateCache(this);
                  swiperAnimate(this);
              },
              slideChangeTransitionEnd: function(){
                  swiperAnimate(this);
              }
          }
      });
  }
};

var homeMap = {

  swiper: null,
  $slider: null,
  $pagination: null,

  init: function() {

      var $map = $('#section-map');
      if ($map.length <= 0) {
          return;
      }
      var lat = $map.data('lat');
      var lng = $map.data('lng');
      var simplerweb = new google.maps.LatLng(lat,lng);

      var myOpts = {
          center:    simplerweb,
          zoom:      17,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [{'featureType':'water','elementType':'geometry.fill','stylers':[{'color':'#d3d3d3'}]},{'featureType':'transit','stylers':[{'color':'#808080'},{'visibility':'off'}]},{'featureType':'road.highway','elementType':'geometry.stroke','stylers':[{'visibility':'on'},{'color':'#b3b3b3'}]},{'featureType':'road.highway','elementType':'geometry.fill','stylers':[{'color':'#ffffff'}]},{'featureType':'road.local','elementType':'geometry.fill','stylers':[{'visibility':'on'},{'color':'#ffffff'},{'weight':1.8}]},{'featureType':'road.local','elementType':'geometry.stroke','stylers':[{'color':'#d7d7d7'}]},{'featureType':'poi','elementType':'geometry.fill','stylers':[{'visibility':'on'},{'color':'#ebebeb'}]},{'featureType':'administrative','elementType':'geometry','stylers':[{'color':'#a7a7a7'}]},{'featureType':'road.arterial','elementType':'geometry.fill','stylers':[{'color':'#ffffff'}]},{'featureType':'road.arterial','elementType':'geometry.fill','stylers':[{'color':'#ffffff'}]},{'featureType':'landscape','elementType':'geometry.fill','stylers':[{'visibility':'on'},{'color':'#efefef'}]},{'featureType':'road','elementType':'labels.text.fill','stylers':[{'color':'#696969'}]},{'featureType':'administrative','elementType':'labels.text.fill','stylers':[{'visibility':'on'},{'color':'#737373'}]},{'featureType':'poi','elementType':'labels.icon','stylers':[{'visibility':'off'}]},{'featureType':'poi','elementType':'labels','stylers':[{'visibility':'off'}]},{'featureType':'road.arterial','elementType':'geometry.stroke','stylers':[{'color':'#d6d6d6'}]},{'featureType':'road','elementType':'labels.icon','stylers':[{'visibility':'off'}]},{},{'featureType':'poi','elementType':'geometry.fill','stylers':[{'color':'#dadada'}]}]
      };
      var map = new google.maps.Map($map[0], myOpts);
      marker = new google.maps.Marker({
          map:        map,
          position:   simplerweb,
          icon: {
              url: '/themes/kancelaria/assets/img/map-marker.png',
              scaledSize: new google.maps.Size(61, 59),
              anchor: new google.maps.Point(30, 30),
          },
      });
  }
};

var parallaxBg = {

    $images: null,

    init: function() {
        this.$images = $(".section--bg-parallax");

        if (!this.$images.length)
            return;

        this.doParallax();
        this.connectEvents();
    },

    connectEvents: function() {
        $(window).on("scroll", $.proxy(this.doParallax, this));
    },

    getViewportHeight: function() {
        var a = document.documentElement.clientHeight, b = window.innerHeight;
        return a < b ? b : a;
    },

    getViewportScroll: function() {
        if(typeof window.scrollY != 'undefined') {
            return window.scrollY;
        }
        if(typeof pageYOffset != 'undefined') {
            return pageYOffset;
        }
        var doc = document.documentElement;
        doc = doc.clientHeight ? doc : document.body;
        return doc.scrollTop;
    },

    doParallax: function() {

        if (window.innerWidth >= 992) {
            var el, elOffset, elHeight,
                offset = this.getViewportScroll(),
                vHeight = this.getViewportHeight();

            this.$images.each(function (i, el) {
                elOffset = el.offsetTop;
                elHeight = el.offsetHeight;

                if ((elOffset > offset + vHeight) || (elOffset + elHeight < offset)) {
                    return;
                }

                el.style.backgroundPosition = '50% ' + Math.round((elOffset - offset) * 3 / 8) + 'px';
            });
        } else {
            this.$images.css("background-position", "center");
        }
    }
};


var animations = {

    init: function() {
        if (window.sr) {
            $('[data-sr-id]').removeAttr('data-sr-id').removeAttr('style');
        }

        // Changing the defaults
        var sequenceInterval = 100;
        window.sr = ScrollReveal({ reset: false });

        var config = {
            origin: 'left',
            duration: 1000,
            scale: 0,
            delay: 500
        };

        $('.section').each(function() {
            var sequenceDelay = 0;
            $(this).find('.section__title, .section__icon, .section__text').each(function() {
                if ($(this).hasClass('section__text')) {
                    sr.reveal(this, $.extend( config, { delay: sequenceDelay, viewFactor: 0 } ));
                } else {
                    sr.reveal(this, $.extend( config, { delay: sequenceDelay } ));
                }
                sequenceDelay += sequenceInterval;
            });

            $(this).find('.js-anim-box').each(function() {
                sr.reveal(this, $.extend( config, { delay: sequenceDelay } ));
                sequenceDelay += sequenceInterval;
            });
        });

        $('.swiper-scroll-down').on('click', function() {
            $('html, body').animate({
                scrollTop: $('.section:first').offset().top
            }, 500);
        });
    }
};

$(function () {
  mainSlider.init();
  parallaxBg.init();
  animations.init();
});
